// countryCodes.ts
export const countryCodes = [
    { code: "+54", name: "Argentina" },
    { code: "+591", name: "Bolivia" },
    { code: "+55", name: "Brasil" },
    { code: "+56", name: "Chile" },
    { code: "+57", name: "Colombia" },
    { code: "+506", name: "Costa Rica" },
    { code: "+53", name: "Cuba" },
    { code: "+593", name: "Ecuador" },
    { code: "+503", name: "El Salvador" },
    { code: "+34", name: "España" },
    { code: "+1", name: "Estados Unidos" },
    { code: "+502", name: "Guatemala" },
    { code: "+504", name: "Honduras" },
    { code: "+52", name: "México" },
    { code: "+505", name: "Nicaragua" },
    { code: "+507", name: "Panamá" },
    { code: "+595", name: "Paraguay" },
    { code: "+51", name: "Perú" },
    { code: "+598", name: "Uruguay" },
    { code: "+58", name: "Venezuela" }
  ];