import React from 'react';
import './CustomModal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

interface CustomModalProps {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  children: React.ReactNode;
  type: 'success' | 'error'; // Nueva propiedad para indicar el tipo de mensaje
}

const CustomModal: React.FC<CustomModalProps> = ({ isOpen, onClose, title, children, type }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          {type === 'success' && <FontAwesomeIcon icon={faCheckCircle} className="modal-icon success-icon" />}
          {type === 'error' && <FontAwesomeIcon icon={faTimesCircle} className="modal-icon error-icon" />}
          <h2>{title}</h2>
        </div>
        <div>{children}</div>
        <button onClick={onClose}>Cerrar</button>
      </div>
    </div>
  );
};

export default CustomModal;