import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import './App.css';
import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';
import Service from './components/Service';
import Training from './components/training/Training';
import FreeCourses from './components/training/FreeCourses';
import SpecializationML from './components/training/SpecializationML';
import HomePage from './components/HomePage';
import Register from './components/training/Register';




const App: React.FC = () => {

  return (
    <div className="App">
      <Router>
        <Header/>
          <Routes>
            <Route path="/" element={<HomePage/>} />
            <Route path="/aboutUs" element={<AboutUs />} />
            <Route path="/service" element={<Service />} />
            <Route path="/training" element={<Training/>} />
            <Route path="/training/free-courses" element={<FreeCourses />} />
            <Route path="/training/specializationML" element={<SpecializationML />} />
            <Route path="/training/register" element={<Register/>} />
            <Route path="/contactUs" element={<ContactUs />} />
          </Routes>
        <Footer />
      </Router>
    </div>
  );
};

export default App;