import React from 'react';
import './AboutUs.css';

const AboutUs: React.FC = () => {
  return (
    <div className="aboutUs">
      <div className="section left">
        <h2>Nosotros</h2>
        <p>En Synapse.AI, transformamos datos en soluciones innovadoras para impulsar el éxito de las empresas. Nos especializamos en servicios de Machine Learning y Deep Learning, además de ofrecer consultoría y capacitación en todo lo relacionado con datos.</p>
      </div>
      <div className="section right">
        <h2>Nuestra Misión</h2>
        <p>Nuestra misión es empoderar a las organizaciones mediante la toma de decisiones basadas en datos, optimizando procesos y descubriendo nuevas oportunidades de crecimiento. Creemos que el Machine Learning y el Deep Learning son herramientas esenciales para el avance empresarial.</p>
      </div>
      <div className="section left">
        <h2>Compromiso con la Excelencia</h2>
        <p>En Synapse.AI, nos comprometemos con la excelencia en cada proyecto. Nos esforzamos por mantenernos a la vanguardia de la tecnología, garantizando que nuestras soluciones no solo sean efectivas, sino también sostenibles y escalables.</p>
      </div>
      <div className="section right">
        <h2>Qué Hacemos</h2>
        <ul>
          <li>Servicios de Machine Learning y Deep Learning: Desarrollamos e implementamos soluciones personalizadas para satisfacer las necesidades específicas de cada empresa. Desde la predicción de ventas hasta el análisis de sentimientos, nuestras soluciones están diseñadas para ofrecer resultados concretos y medibles.</li>
          <li>Consultoría Basada en Datos: Asesoramos a las empresas en la identificación, comprensión y aprovechamiento de sus datos para mejorar su rendimiento. Nuestro equipo de expertos colabora estrechamente con los clientes para diseñar estrategias que maximicen el valor de sus datos.</li>
          <li>Capacitación y Especialización: Ofrecemos una amplia gama de cursos de capacitación, incluyendo una especialización en Machine Learning. Nuestros programas están diseñados para equipar a profesionales con las habilidades necesarias para sobresalir en el campo de la ciencia de datos.</li>
        </ul>
      </div>
      
    </div>
  );
};

export default AboutUs;