import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import './Header.css';

const Header: React.FC = () => {
  const location = useLocation();
  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const closeMenu = () => {
    setMenuVisible(false);
  };

  return (
    <header className="header">
      <div className="logo">
      <Link to="/">
          <img src="/images/logo.png" alt="synapse.ai" />
        </Link>
      </div>
      <button className="menu-button" onClick={toggleMenu}>☰</button>
      <nav className={`menu ${menuVisible ? 'show' : ''}`}>
        <Link
          to="/aboutUs"
          className={location.pathname === '/aboutUs' ? 'active' : ''}
          onClick={closeMenu}
        >
          Nosotros
        </Link>
        <Link
          to="/service"
          className={location.pathname === '/service' ? 'active' : ''}
          onClick={closeMenu}
        >
          Servicios
        </Link>
        <Link
        to="/training"
        className={
          location.pathname === '/training' ||
          location.pathname === '/training/specializationML' ||
          location.pathname === '/training/free-courses'
            ? 'active'
            : ''
        }
        onClick={closeMenu}
      >
        Capacitaciones
      </Link>
          {/* {location.pathname.startsWith('/training') && (
          <Link
            to="/training/register"
            className={location.pathname === '/training/register' ? 'active' : ''}
            onClick={closeMenu}
          >
            Regístrate
          </Link>
        )} */}
        <Link
          to="/contactUs"
          className={location.pathname === '/contactUs' ? 'active' : ''}
          onClick={closeMenu}
        >
          Contáctanos
        </Link>
      </nav>
    </header>
  );
};

export default Header;