import React from 'react';
import { Link } from 'react-router-dom';
import './HomePage.css'; // Asegúrate de tener un archivo CSS para los estilos

const HomePage = () => {
  return (
    <div className="home-container">
      <div className="image-container">
        <Link to="/service">
          <img src="/images/service.jpg" alt="Servicios" />
          <h2>Servicios</h2>
        </Link>
      </div>
      <div className="image-container">
        <Link to="/training">
          <img src="/images/training.jpg" alt="Capacitaciones" />
          <h2>Capacitaciones</h2>
        </Link>
      </div>
    </div>
  );
};

export default HomePage;