import React from 'react';
import { FaYoutube, FaDiscord, FaFacebook, FaInstagram } from 'react-icons/fa';
import './Footer.css';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <a href={process.env.REACT_APP_YOUTUBE_CHANNEL} target="_blank" rel="noopener noreferrer">
        <FaYoutube />
      </a>
      <a href="https://discord.gg/TXsygQJsdb" target="_blank" rel="noopener noreferrer">
        <FaDiscord />
      </a>
      <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">
        <FaFacebook />
      </a>
      <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
        <FaInstagram />
      </a>
    </footer>
  );
};

export default Footer;