import React from 'react';
import './SpecializationML.css';
import { useNavigate } from 'react-router-dom';

const Module: React.FC<{ title: string; items: string[] }> = ({ title, items }) => (
  <div>
    <h1>{title}</h1>
    <ul>
      {items.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  </div>
);

const Project: React.FC<{ title: string; description: string }> = ({ title, description }) => (
  <div>
    <h2>{title}</h2>
    <p>{description}</p>
  </div>
);

const SpecializationML: React.FC = () => {

  const navigate = useNavigate();

  const handleRegisterClick = () => {
    navigate('/training/register');
  };

  return (
    <div className="specialization-ml">
      <h1>En esta especialización de Machine Learning aprenderás...</h1>
      <div className="content">
        <div className="text-section">
          <Module
            title="Módulo 1: Preprocesamiento de datos para ML (8 horas)"
            items={[
              'Introducción al preprocesamiento de datos',
              'Feature Engineering',
            ]}
          />
          <Module
            title="Módulo 2: Machine Learning (32 horas)"
            items={[
              'Introducción a Machine Learning',
              'Aprendizaje supervisado',
              'Aprendizaje no supervisado',
            ]}
          />
          <Module
            title="Módulo 3: Deep Learning (32 horas)"
            items={[
              'Introducción a Deep Learning',
              'Redes neuronales artificiales (ANN)',
              'Redes neuronales convolucionales (CNN)',
              'Redes neuronales recurrentes (RNN)',
              'Procesamiento de lenguaje natural (NLP)',
              'Optimización de redes neuronales',
            ]}
          />
          <div>
            <h1>Módulo 4: Proyectos (2 semanas)</h1>
            <p>En este módulo, los estudiantes aplicarán los conocimientos adquiridos a lo largo del curso en proyectos prácticos que les permitirán consolidar y demostrar sus habilidades en Machine Learning y Deep Learning.</p>
            <Project
              title="Proyecto 1: Machine Learning"
              description="Descripción: En este proyecto, los estudiantes desarrollarán un modelo de Machine Learning para resolver un problema de predicción basado en datos tabulares."
            />
            <Project
              title="Proyecto 2: Deep Learning con CNN"
              description="Descripción: En este proyecto, los estudiantes desarrollarán un clasificador de imágenes utilizando Redes Neuronales Convolucionales (CNN) para identificar diferentes categorías de imágenes."
            />
          </div>
        </div>
        <div className="image-section">
          <img src="/images/specialization2.jpg" alt="Machine Learning" />
        </div>
      </div>
      <div className="banner-buttons-container">
        <div className="banner">
          <div className="inner-banner">
            <p className="animated-text">¿Te unes al reto?</p>
          </div>
        </div>
        <div className="buttons">
          <button className="register-button" onClick={handleRegisterClick}>
            Regístrate
          </button>
          <a href="/Programa.pdf" download>
            <button className="download-button">Descargar Programa</button>
          </a>
        </div>
      </div>
    </div> 
  );
};

export default SpecializationML;