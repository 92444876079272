import React from 'react';
import './ContactUs.css';

const ContactUs: React.FC = () => {
  return (
    <div className="container-contact-us">
      <div className="contact-us">
        <h1>Contáctanos</h1>
        <div className="contact-method">
          <h2>WhatsApp</h2>
          <p>
            <a href="https://wa.me/573012616255" target="_blank" rel="noopener noreferrer">
              +573012616255
            </a>
          </p>
        </div>
        <div className="contact-method">
          <h2>Discord</h2>
          <p>
            <a href="https://discord.gg/TXsygQJsdb" target="_blank" rel="noopener noreferrer">
              https://discord.gg/TXsygQJsdb
            </a>
          </p>
        </div>
        <div className="contact-method">
          <h2>Correo Electrónico</h2>
          <p>consultas@groupsynapseai.com</p>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;