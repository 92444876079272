import React, { useState } from 'react';
import axios from 'axios';
import { validateForm } from './RegisterValidation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaypal } from '@fortawesome/free-brands-svg-icons';
import { faMoneyBillWave, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import CustomModal from './CustomModal';
import './Register.css';
import { countryCodes } from './CountryCodes';

interface FormData {
  name: string;
  lastName: string;
  idNumber: string;
  email: string;
  birthDate: string;
  educationLevel: string;
  cellphone: string;
  countryCode: string; // Nuevo campo para el código de área del país
}

interface FormErrors {
  name: string;
  lastName: string;
  idNumber: string;
  email: string;
  birthDate: string;
  educationLevel: string;
  cellphone: string;
  countryCode: string; // Nuevo campo para el código de área del país
}

const Register: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    name: '',
    lastName: '',
    idNumber: '',
    email: '',
    birthDate: '',
    educationLevel: '',
    cellphone: '',
    countryCode: '' // Nuevo campo para el código de área del país
  });

  const [errors, setErrors] = useState<FormErrors>({
    name: '',
    lastName: '',
    idNumber: '',
    email: '',
    birthDate: '',
    educationLevel: '',
    cellphone: '',
    countryCode: '' // Nuevo campo para el código de área del país
  });

  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const registerUrl = process.env.REACT_APP_CREATE_STUDENT || '';
  const authLoginUrl = process.env.REACT_APP_AUTH_LOGIN || '';

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    const validationErrors = validateForm(formData);
    setErrors(validationErrors);

    const isValid = Object.values(validationErrors).every(error => error === '');
    if (isValid) {
      try {
        // Generar o recuperar el token Bearer
        const token = await generateBearerToken();
        console.log('formData:', formData);
        const response = await axios.post(registerUrl, formData, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          }
        });
        console.log('Registro exitoso:', response.data);
        // Mostrar el modal de éxito
        setShowModal(true);
        // Limpiar el formulario
        setFormData({
          name: '',
          lastName: '',
          idNumber: '',
          email: '',
          birthDate: '',
          educationLevel: '',
          cellphone: '',
          countryCode: ''
        });
      } catch (error) {
        console.error('Error en el registro:', error);
        // Mostrar el modal de error
        setShowErrorModal(true);
      } finally {
        setIsSubmitting(false);
      }
    } else {
      setIsSubmitting(false);
    }
  };

  const generateBearerToken = async (): Promise<string> => {
    const authData = {
      username: process.env.REACT_APP_AUTH_USERNAME,
      password: process.env.REACT_APP_AUTH_PASSWORD
    };
    const response = await axios.post(authLoginUrl, authData);
    return response.data.access_token;
  };

  return (
    <div className={`register-container ${isSubmitting ? 'waiting' : ''}`}>
      <div className="register-form">
        <h2>Regístrate</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Nombres:</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            {errors.name && <span className="error">{errors.name}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="lastName">Apellidos:</label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              required
            />
            {errors.lastName && <span className="error">{errors.lastName}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="idNumber">Número de Identificación:</label>
            <input
              type="text"
              id="idNumber"
              name="idNumber"
              value={formData.idNumber}
              onChange={handleChange}
              required
            />
            {errors.idNumber && <span className="error">{errors.idNumber}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="email">Correo:</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            {errors.email && <span className="error">{errors.email}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="birthDate">Fecha de Nacimiento:</label>
            <input
              type="date"
              id="birthDate"
              name="birthDate"
              value={formData.birthDate}
              onChange={handleChange}
              required
              className="form-control"
            />
            {errors.birthDate && <span className="error">{errors.birthDate}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="educationLevel">Nivel de Educación:</label>
            <select
              id="educationLevel"
              name="educationLevel"
              value={formData.educationLevel}
              onChange={handleChange}
              required
            >
              <option value="">Seleccione</option>
              <option value="Primaria">Primaria</option>
              <option value="Secundaria">Secundaria</option>
              <option value="Universidad">Universidad</option>
              <option value="Postgrado">Postgrado</option>
            </select>
            {errors.educationLevel && <span className="error">{errors.educationLevel}</span>}
          </div>
          <div className="form-group">
            <label htmlFor="cellphone">Teléfono:</label>
            <div className="phone-input">
              <select
                id="countryCode"
                name="countryCode"
                value={formData.countryCode}
                onChange={handleChange}
                required
                className="country-code-select"
              >
                <option value="">Código de país</option>
                {countryCodes.map((country) => (
                  <option key={country.code} value={country.code}>
                    {country.name} ({country.code})
                  </option>
                ))}
              </select>
              <input
                type="tel"
                id="cellphone"
                name="cellphone"
                value={formData.cellphone}
                onChange={handleChange}
                required
                className="cellphone-input"
              />
            </div>
            {errors.cellphone && <span className="error">{errors.cellphone}</span>}
          </div>
          <div className="button-container">
            <button type="submit">Regístrate</button>
          </div>
        </form>
      </div>
      <div className="divider"></div>
      <div className="payment-steps">
        <h2>Medios de pago</h2>
        <h3>Pagos para Argentina:</h3>
        <ol className="no-list-style">
          <li><FontAwesomeIcon icon={faMoneyBillWave} /> Mercado Pago: leonardo.scelza.mp</li>
          <li><FontAwesomeIcon icon={faMoneyBillWave} /> Cuenta bancaria pesos: leonardo.scelza</li>
          <li><FontAwesomeIcon icon={faDollarSign} /> Cuenta bancaria dólares: leonardo.scelza.d</li>
        </ol>
        <h3>Pagos para otras partes del mundo:</h3>
        <ol className="no-list-style">
          <li><FontAwesomeIcon icon={faPaypal} /> PayPal: nes164@gmail.com</li>
        </ol>
        <h3>Pagos para Colombia:</h3>
        <ol className="no-list-style">
          <li>PSE: <a href="https://linkdepagospse.rappipay.co/lbm9Dd" target="_blank" rel="noopener noreferrer">
            https://linkdepagospse.rappipay.co/lbm9Dd</a>
          </li>
          <li><img src="/images/QR_Nequi.jpg" alt="Nequi" className="payment-icon" />
          </li>
        </ol>
        <p className="payment-note">Recuerda enviar tu comprobante de pago a <a href="mailto:consultas@groupsynapseai.com">consultas@groupsynapseai.com</a></p>
      </div>

      <CustomModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        title="Registro Exitoso"
        type="success"
      >
        <p>El registro se ha realizado con éxito.</p>
      </CustomModal>

      <CustomModal
        isOpen={showErrorModal}
        onClose={() => setShowErrorModal(false)}
        title="Error en el Registro"
        type="error"
      >
        <p>Hubo un error en el registro. Por favor, inténtalo de nuevo.</p>
      </CustomModal>
    </div>
  );
};

export default Register;