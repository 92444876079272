import React from 'react';
import './FreeCourses.css';

const FreeCourses: React.FC = () => {
    const navigateTo = (url: string) => {
        window.open(url, '_blank'); // Abre el enlace en una nueva pestaña
    };

    return (
        <div className="free-courses">
            <div className="workshop" onClick={() => navigateTo(process.env.REACT_APP_YOUTUBE_CHANNEL || '')}>
                <img src="/images/workshop_081124.jpg" alt="WorkShop Computer Vision" className="workshop-img" />
            </div>
        </div>
    );
};

export default FreeCourses;