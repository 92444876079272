export interface FormData {
  name: string;
  lastName: string;
  idNumber: string;
  email: string;
  birthDate: string;
  educationLevel: string;
  cellphone: string;
  countryCode: string; // Nuevo campo para el código de área del país
}

export interface FormErrors {
  name: string;
  lastName: string;
  idNumber: string;
  email: string;
  birthDate: string;
  educationLevel: string;
  cellphone: string;
  countryCode: string; // Nuevo campo para el código de área del país
}

export const validateForm = (formData: FormData): FormErrors => {
  let errors: FormErrors = {
    name: '',
    lastName: '',
    idNumber: '',
    email: '',
    birthDate: '',
    educationLevel: '',
    cellphone: '',
    countryCode: '' // Nuevo campo para el código de área del país
  };

  if (!formData.name) {
    errors.name = 'El nombre es requerido';
  }
  if (!formData.lastName) {
    errors.lastName = 'El apellido es requerido';
  }
  if (!formData.idNumber) {
    errors.idNumber = 'El número de identificación es requerido';
  }
  if (!formData.email) {
    errors.email = 'El correo es requerido';
  } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
    errors.email = 'El correo no tiene un formato válido';
  }
  if (!formData.birthDate) {
    errors.birthDate = 'La fecha de nacimiento es requerida';
  }
  if (!formData.educationLevel) {
    errors.educationLevel = 'El nivel de educación es requerido';
  }
  if (!formData.cellphone) {
    errors.cellphone = 'El teléfono es requerido';
  } else if (!/^\d{10,15}$/.test(formData.cellphone)) {
    errors.cellphone = 'El teléfono debe ser un número válido de 10 a 15 dígitos';
  }
  if (!formData.countryCode) {
    errors.countryCode = 'El código de país es requerido';
  }

  return errors;
};