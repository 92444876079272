import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Training.css';

const Training: React.FC = () => {
    const navigate = useNavigate();

    const navigateTo = (path: string) => {
        navigate(path);
    };

    return (
        <div className="trainings">
            <div className="training" onClick={() => navigateTo('/training/specializationML')}>
                <img src="/images/specializationML.jpg" alt="Especialización en Machine Learning" className="training-img" />
                <div className="watermark">Próximamente</div>
                <h2 className="training-title">Especialización en Machine Learning</h2>
            </div>
            <div className="training" onClick={() => navigateTo('/training/free-courses')}>
                <img src="/images/freeCourses.jpg" alt="Cursos Gratis en Machine Learning" className="training-img" />
                {/* <div className="watermark">Próximamente</div> */}
                <h2 className="training-title">Cursos Gratis</h2>
            </div>
        </div>
    );
};

export default Training;