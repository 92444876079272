import React from 'react';
import './Service.css';

const Service: React.FC = () => {
    return (
        <div className="services">
            <video className="service-video" controls autoPlay>
                <source src="/videos/Synapse.AI.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <section className="service">
                <img src="/images/ML.jpg" alt="Servicio de Machine Learning" className="service-img" />
                <div className="service-content">
                    <h1 className="service-title">Machine Learning</h1>
                    <ul className="service-texto">
                        <li>Desarrollo de modelos de Machine Learning</li>
                        <li>Implementación de soluciones de Machine Learning</li>
                        <li>Optimización y mejora de los modelos</li>
                    </ul>
                </div>
            </section>
            <section className="service">
                <div className="service-content">
                    <h1 className="service-title">Análisis de datos</h1>
                    <ul className="service-texto">
                        <li>Análisis de datos descriptivo</li>
                        <li>Análisis predictivo - Modelos Predictivos, Forecasting</li>
                        <li>Análisis prescriptivo - Optimización, Modelos de Decisión</li>
                        <li>Visualización de Datos - Dashboards Interactivos, Storytelling con Datos</li>
                        <li>Análisis de sentimientos - Análisis de Opiniones, Medición de Satisfacción</li>
                    </ul>
                </div>
                <img src="/images/AD.jpg" alt="Servicio de Análisis de datos" className="service-img" />
            </section>
            <section className="service">
                <img src="/images/db.jpg" alt="Servicio de Base de datos" className="service-img" />
                <div className="service-content">
                    <h1 className="service-title">Base de datos</h1>
                    <ul className="service-texto">
                        <li>Diseño y arquitectura de base de datos</li>
                        <li>Administración y mantenimiento</li>
                        <li>Base de datos en la nube</li>
                        <li>Auditoría y evaluación de base de datos existentes</li>
                    </ul>
                </div>
            </section>
        </div>
    );
};

export default Service;